import { ConfigMap, DataMap } from "./ConfigDataMap";

export class ConfigData {
  pages: Page[];

  constructor(data: any) {
    const pages: Page[] = [];

    for (const page in data) {
      if (data.hasOwnProperty(page)) {
        const map = DataMap.pages.find((p) => p.pageDetails.dataName === page);
        if (map) {
          const isList = data[page].length > 0;
          pages.push(
            new Page(map.pageDetails.displayName, map.columns, isList ? data[page] : [data[page]]),
          );
        }
      }
    }

    this.pages = pages;
  }
}

export class Page {
  title: string;
  columns: ConfigMap[];
  rows: string[][];

  constructor(title: string, columns: ConfigMap[], data: any) {
    this.title = title;
    this.columns = columns;

    const rows: string[][] = data.map((p: { [x: string]: any }) =>
      columns.map((column) => p[column.dataName]),
    );

    this.rows = rows;
  }

  matchesSearch(search: string) {
    if (search.trim() === "") {
      return true;
    }
    const searchLower = search.toLowerCase();
    return (
      this.title.toLowerCase().includes(searchLower) ||
      this.columns.some((p) => p.displayName.toLowerCase().includes(searchLower))
    );
  }
}
