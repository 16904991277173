import React, { useState, useContext } from "react";
import { ListGroup, ListGroupItem, Collapse } from "reactstrap";

import { ToolContext } from "../context/ToolContext";
import IconRadioGrp from "./IconRadioGrp";

const FaultFilterSubGroup = ({ result, faults, setValue, defaultSeason }) => {
  const { toolState } = useContext(ToolContext);
  const [collapse, setCollapse] = useState(false);

  const toggle = (result, value) => {
    if (result.value === value.value) {
      setValue({ value: "", isSet: false });
    } else {
      if (defaultSeason && value.season) {
        setValue({ value: value.value, isSet: true, season: defaultSeason });
      }
      setValue({ value: value.value, isSet: true });
    }
  };

  const getFaults = (result, value) => {
    return faults.find(
      (f) => f.value === (result.season ? result.season + value.value : value.value),
    ).constraintCount;
  };

  const getFaultsGroup = (result) => {
    return result.options.some((value) => getFaults(result, value) > 0);
  };

  return (
    <>
      <ListGroupItem
        key={result.name}
        tag="a"
        action
        className={`d-flex justify-content-between text-${result.isSet ? result.color : "black"}`}
        onClick={() => setCollapse(!collapse)}
      >
        <span>
          {result.name}
          {faults.length > 0 && toolState.showResults && getFaultsGroup(result) && (
            <span className="badge badge-danger ml-1">!</span>
          )}
        </span>
        <span>
          {result.isSet && result.season && (
            <IconRadioGrp
              array={[
                { name: "summer", color: "warning", icon: "sun" },
                { name: "winter", color: "info", icon: "snowflake" },
                { name: "All", color: "light", icon: "all" },
              ]}
              value={result.season}
              setValue={(e) => setValue(e, result)}
            />
          )}
          <span className="list-group-item-icon">
            <i className={`icon-${collapse ? "chevron-down" : "chevron-right"}`}></i>
          </span>
        </span>
      </ListGroupItem>
      <Collapse isOpen={collapse}>
        <ListGroup flush>
          {result.options.map((value) => (
            <ListGroupItem
              key={value.id}
              tag="a"
              className={`d-flex justify-content-between text-${
                result.value === value.value ? result.color : "black"
              }`}
              onClick={() => toggle(result, value)}
            >
              <span>
                {value.name}
                {faults.length > 0 && toolState.showResults && getFaults(result, value) > 0 && (
                  <span className="badge badge-danger ml-1">{getFaults(result, value)}</span>
                )}
              </span>
              <span className="list-group-item-icon">
                <i className={`icon-${result.value === value.value ? "check" : "cross"}-solid`}></i>
              </span>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Collapse>
    </>
  );
};
export default FaultFilterSubGroup;
