export interface ConfigMapData {
  pages: ConfigMapPage[];
}

export interface ConfigMapPage {
  pageDetails: ConfigMap;
  columns: ConfigMap[];
}

export interface ConfigMap {
  displayName: string;
  dataName: string;
}

export const DataMap: ConfigMapData = {
  pages: [
    {
      pageDetails: {
        displayName: "Cable Auto Select",
        dataName: "autoSelectGroups",
      },
      columns: [
        { displayName: "Cable Class", dataName: "cableClass" },
        { displayName: "Cable Type", dataName: "name" },
      ],
    },
    {
      pageDetails: {
        displayName: "Cable Ratings",
        dataName: "cableRatingProfiles",
      },
      columns: [
        { displayName: "Cable Type", dataName: "cableType" },
        { displayName: "Season", dataName: "profileName" },
        { displayName: "Rating Type", dataName: "ratingType" },
        { displayName: "Rating (A)", dataName: "ratingA" },
      ],
    },
    {
      pageDetails: {
        displayName: "Cable Type Properties",
        dataName: "cableTypes",
      },
      columns: [
        { displayName: "Cable Type", dataName: "cableType" },
        {
          displayName: "Operating Phase Resistance (miliohms/m)",
          dataName: "operatingPhaseResistancePerMetre",
        },
        {
          displayName: "Operating Neutral Resistance (miliohms/m)",
          dataName: "operatingNeutralResistancePerMetre",
        },
        {
          displayName: "Operating Phase Reactance (miliohms/m)",
          dataName: "operatingPhaseReactancePerMetre",
        },
        {
          displayName: "Operating Neutral Reactance (miliohms/m)",
          dataName: "operatingNeutralReactancePerMetre",
        },
        {
          displayName: "Fault Phase Resistance (miliohms/m)",
          dataName: "faultPhaseResistancePerMetre",
        },
        {
          displayName: "Fault Neutral Resistance (miliohms/m)",
          dataName: "faultNeutralResistancePerMetre",
        },
        {
          displayName: "Fault Phase Reactance (miliohms/m)",
          dataName: "faultPhaseReactancePerMetre",
        },
        {
          displayName: "Fault Neutral Reactance (miliohms/m)",
          dataName: "faultNeutralReactancePerMetre",
        },
      ],
    },
    {
      pageDetails: {
        displayName: "Constraint Thresholds",
        dataName: "constraints",
      },
      columns: [
        { displayName: "Max Volt Drop Mains (%)", dataName: "maxVoltDropMainsPercent" },
        { displayName: "Max Volt Drop Service (%)", dataName: "maxVoltDropServicePercent" },
        { displayName: "Max Volt Rise (%)", dataName: "maxVoltRisePercent" },
        { displayName: "Maximum Utilisation (%)", dataName: "maximumUtilisationPercent" },
        { displayName: "Transformer Utilisation (%)", dataName: "transformerUtilisation" },
        {
          displayName: "Flicker Starting Voltage Disturbance Threshold (%)",
          dataName: "flickerStartingVoltageDisturbanceThreshold",
        },
        {
          displayName: "Loop Operating Impedance Mains Threshold (mO)",
          dataName: "loopOperatingImpedanceMainsThreshold",
        },
        {
          displayName: "Loop Operating Impedance Service Threshold (mO)",
          dataName: "loopOperatingImpedanceServiceThreshold",
        },
        {
          displayName: "Source Loop Operating Impedance Mains Threshold (mO)",
          dataName: "sourceLoopOperatingImpedanceMainsThreshold",
        },
        {
          displayName: "Source Loop Operating Impedance Service Threshold (mO)",
          dataName: "sourceLoopOperatingImpedanceServiceThreshold",
        },
      ],
    },
    {
      pageDetails: {
        displayName: "Customer Profiles",
        dataName: "consumerLoadProfiles",
      },
      columns: [
        { displayName: "Consumer Class", dataName: "consumerClass" },
        { displayName: "Load Profile", dataName: "loadProfile" },
      ],
    },
    {
      pageDetails: {
        displayName: "System Voltage",
        dataName: "globalSettings",
      },
      columns: [{ displayName: "System Voltage (V)", dataName: "systemVoltage" }],
    },
    {
      pageDetails: {
        displayName: "Minimum Fault Levels",
        dataName: "minimumFaultLevels",
      },
      columns: [
        { displayName: "Cable Type", dataName: "cableType" },
        { displayName: "Fuse Rating (A)", dataName: "fuseRatingA" },
        { displayName: "Minimum Fault Level (A)", dataName: "minimumFaultLevelA" },
      ],
    },
    {
      pageDetails: {
        displayName: "Transformer Auto Select",
        dataName: "transformerAutoSelectGroups",
      },
      columns: [
        { displayName: "Mounting", dataName: "mounting" },
        { displayName: "Phase", dataName: "phase" },
        { displayName: "Rating (kVA)", dataName: "ratingkVA" },
      ],
    },
    {
      pageDetails: {
        displayName: "Transformer Properties",
        dataName: "transformerRatings",
      },
      columns: [
        { displayName: "Mounting", dataName: "mounting" },
        { displayName: "Rating (kVA)", dataName: "ratingkVA" },
        { displayName: "Resistance (O)", dataName: "resistanceO" },
        { displayName: "Reactance (O)", dataName: "reactanceO" },
        { displayName: "Default Fuse Rating (A)", dataName: "defaultFuseRatingA" },
      ],
    },
    {
      pageDetails: {
        displayName: "Transformer Ratings",
        dataName: "transformerRatingProfiles",
      },
      columns: [
        { displayName: "Season", dataName: "profileName" },
        { displayName: "Mounting", dataName: "mounting" },
        { displayName: "Transformer Size (kVA)", dataName: "transformerSizekVA" },
        { displayName: "Rating Type", dataName: "ratingType" },
        { displayName: "Rating (kVA)", dataName: "ratingkVA" },
      ],
    },
  ],
};
