import {
  updateCable,
  updateGroupedConnection,
  updateGroupedConnectionChild,
  updateMotor,
  updateTransformer,
  updateWelder,
} from "../../app/networkSlice";
import * as phase from "../../constants/phase";

export const shouldShowPhasesForm = (ringfencedTypes, selected) => {
  const shouldShow = (type) => {
    switch (type) {
      case "Transformers":
      case "Pole-mounted Transformer":
      case "Ground-mounted Transformer":
      case "Properties":
      case "Domestic Property":
      case "Small Commercial Property":
      case "Large Commercial Property":
      case "Industrial Property":
      case "Conductors":
      case "Mains Overhead Line":
      case "Mains Underground Cable":
      case "Service Overhead Line":
      case "Service Underground Cable":
      case "LCT":
      case "Generator":
      case "Heat Pump":
      case "EV Chargepoint":
      case "Motor":
      case "Welder":
      case "Connection":
      case "Grouped Connection":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    const isNotAllowed = ringfencedTypes.some(
      (p) => !["Node", "Service End Node"].includes(p.name) && !shouldShow(p.name),
    );
    return !isNotAllowed;
  }
  return shouldShow(selected);
};

export const refreshNumberOfPhasesWarning = (
  ringfencedFiltered,
  reference,
  numberOfPhases,
  setNumberOfPhasesWarning,
) => {
  let isSet = false;

  const numberOfPhasesProperty = numberOfPhases === "Two" ? "Split" : numberOfPhases;

  if (ringfencedFiltered.some((p) => p.styles.name === "Ground-mounted Transformer")) {
    if (
      reference.transformers.types.every(
        (f) => !f[numberOfPhasesProperty.toLowerCase() + "phase"]["gmt"],
      )
    ) {
      setNumberOfPhasesWarning(true);
      isSet = true;
    }
  }
  if (ringfencedFiltered.some((p) => p.styles.name === "Pole-mounted Transformer")) {
    if (
      reference.transformers.types.every(
        (f) => !f[numberOfPhasesProperty.toLowerCase() + "phase"]["pmt"],
      )
    ) {
      setNumberOfPhasesWarning(true);
      isSet = true;
    }
  }

  if (!isSet) {
    setNumberOfPhasesWarning(false);
  }
};

export const applyPhaseChange = (ringfencedFiltered, numberOfPhases, dispatchRedux) => {
  ringfencedFiltered.forEach((p) => {
    if (p.groupedConnectionPoints?.length > 0) {
      p.groupedConnectionPoints.forEach((q) => {
        if (q.numberOfPhases !== numberOfPhases) {
          dispatchRedux(
            updateGroupedConnectionChild({
              id: p.id,
              childUpdate: { id: q.id, name: "numberOfPhases", value: numberOfPhases },
              isGroupUpdate: false,
            }),
          );
          dispatchRedux(
            updateGroupedConnectionChild({
              id: p.id,
              childUpdate: { id: q.id, name: "numberOfPhasesIsDefault", value: false },
              isGroupUpdate: false,
            }),
          );
          dispatchRedux(
            updateGroupedConnectionChild({
              id: p.id,
              childUpdate: { id: q.id, name: "phaseMap", value: [q.count, 0, 0, 0] },
              isGroupUpdate: false,
            }),
          );
          dispatchRedux(
            updateGroupedConnectionChild({
              id: p.id,
              childUpdate: { id: q.id, name: "autoPhase", value: true },
              isGroupUpdate: false,
            }),
          );
          dispatchRedux(
            updateGroupedConnectionChild({
              id: p.id,
              childUpdate: { id: q.id, name: "`phaseAutoConsumers", value: q.count },
              isGroupUpdate: false,
            }),
          );
          ["1", "2", "3"].forEach((r) => {
            dispatchRedux(
              updateGroupedConnectionChild({
                id: p.id,
                childUpdate: { id: q.id, name: `phasePHASE${r}Consumers`, value: 0 },
                isGroupUpdate: false,
              }),
            );
          });
          dispatchRedux(
            updateGroupedConnectionChild({
              id: p.id,
              childUpdate: { id: q.id, name: "balancedLoad", value: true },
              isGroupUpdate: q.numberOfPhases !== phase.SINGLE,
            }),
          );
          dispatchRedux(
            updateGroupedConnectionChild({
              id: p.id,
              childUpdate: { id: q.id, name: "unbalancePercent", value: 0 },
              isGroupUpdate: q.numberOfPhases !== phase.SINGLE,
            }),
          );
        }
      });
    }

    const update = {
      id: p.id,
      name: "numberOfPhases",
      value: numberOfPhases,
    };
    const update2 = {
      id: p.id,
      name: "numberOfPhasesIsDefault",
      value: false,
    };

    switch (p.styles.name) {
      case "Ground-mounted Transformer":
      case "Pole-mounted Transformer":
        if (update.value === phase.TWO) {
          update.value = "Split";
        }
        dispatchRedux(updateTransformer(update));
        dispatchRedux(updateTransformer(update2));
        break;
      case "Overhead Line":
      case "Underground Cable":
        dispatchRedux(updateCable(update));
        dispatchRedux(updateCable(update2));
        break;
      case "Welder":
        dispatchRedux(updateWelder(update));
        dispatchRedux(updateWelder(update2));
        break;
      case "Motor":
        dispatchRedux(updateMotor(update));
        dispatchRedux(updateMotor(update2));
        break;
    }
  });
};
