import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { getLocalStorageValue } from "../utils/useLocalStorage";

// Define a type for the slice state
export interface AppState {
  showCableLabels: boolean;
  showCableLabelIds: boolean;
  isColorBlindEnabled: boolean;
  isStatusStylingEnabled: boolean;
  isLCTIndicatorEnabled: boolean;
  showMapBackground: boolean;
  showCascadingRagColors: boolean;
  iconSize: number;
  ratingType: string;
  geoJson: any[];
  geoJsonLoading: any[];
}

export const initialState: AppState = {
  showCableLabels: getLocalStorageValue("cableLabels", false),
  showCableLabelIds: getLocalStorageValue("cableLabelIds", false),
  isColorBlindEnabled: getLocalStorageValue("colourBlindEnabled", false),
  isStatusStylingEnabled: getLocalStorageValue("statusStyling", false),
  isLCTIndicatorEnabled: getLocalStorageValue("lctIndicator", true),
  showMapBackground: getLocalStorageValue("showTiles", true),
  showCascadingRagColors: getLocalStorageValue("cascadingRAGColours", true),
  iconSize: getLocalStorageValue("iconSize", 3),
  ratingType: getLocalStorageValue("ratingType", "Sustained"),
  geoJson: [],
  geoJsonLoading: [],
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    showCableLabels: (state) => {
      state.showCableLabels = !state.showCableLabels;
    },
    showCableLabelIds: (state) => {
      state.showCableLabelIds = !state.showCableLabelIds;
    },
    isColorBlindEnabled: (state) => {
      state.isColorBlindEnabled = !state.isColorBlindEnabled;
    },
    isStatusStylingEnabled: (state) => {
      state.isStatusStylingEnabled = !state.isStatusStylingEnabled;
    },
    isLCTIndicatorEnabled: (state) => {
      state.isLCTIndicatorEnabled = !state.isLCTIndicatorEnabled;
    },
    showMapBackground: (state) => {
      state.showMapBackground = !state.showMapBackground;
    },
    showCascadingRagColors: (state) => {
      state.showCascadingRagColors = !state.showCascadingRagColors;
    },
    iconSize: (state, action: PayloadAction<number>) => {
      state.iconSize = action.payload;
    },
    ratingType: (state, action: PayloadAction<string>) => {
      state.ratingType = action.payload;
    },
    removeGeoJsonLayer: (state, action: PayloadAction<any>) => {
      state.geoJson = state.geoJson.filter((geoLayer) => geoLayer !== action.payload);
    },
    addGeoJsonLayer: (state, action: PayloadAction<any>) => {
      state.geoJson = [...state.geoJson, action.payload];
      state.geoJsonLoading = [...state.geoJsonLoading, action.payload];
    },
    removeGeoJsonLoading: (state, action: PayloadAction<any>) => {
      state.geoJsonLoading = state.geoJsonLoading.filter((geoLayer) => geoLayer !== action.payload);
    },
  },
});

export const {
  showCableLabels,
  showCableLabelIds,
  isColorBlindEnabled,
  isStatusStylingEnabled,
  isLCTIndicatorEnabled,
  showMapBackground,
  showCascadingRagColors,
  iconSize,
  ratingType,
  removeGeoJsonLayer,
  addGeoJsonLayer,
  removeGeoJsonLoading,
} = settingsSlice.actions;

export const selectSettings = (state: RootState) => state.settings;

export default settingsSlice.reducer;
