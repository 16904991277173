import React, { useContext } from "react";
import { Pane, Marker, Polyline, useMap } from "react-leaflet";

import { ToolContext } from "../context/ToolContext";
import { FormContext } from "../context/FormContext";
import { iconMarker } from "../utils/iconMarker";
import * as color from "../constants/colors";
import { useAppSelector } from "../app/hooks";

const RagAsset = ({ asset, onClick, ragNetwork }) => {
  const { toolState } = useContext(ToolContext);
  const { formState } = useContext(FormContext);
  const { clientSettings } = formState;
  const colourBlindSettingEnabled = useAppSelector((state) => state.settings.isColorBlindEnabled);
  const colourBlindEnabled =
    clientSettings.Features.ColourBlindEnabled && colourBlindSettingEnabled;
  const cascadingRAGColours = useAppSelector((state) => state.settings.showCascadingRagColors);
  const iconSize = useAppSelector((state) => state.settings.iconSize);
  const cableZoomTrigger = parseInt(clientSettings.General.CableZoomTrigger);
  const cableZoomWeightMultiplier = parseInt(clientSettings.General.CableZoomWeightMultiplier);

  const { mode, activeTool } = toolState;

  const map = useMap();
  const zoom = map.getZoom();

  const getPoint = (latLng) => map.latLngToContainerPoint(latLng);

  const styleIcon = (asset) => {
    const newAsset = { ...asset, styles: { ...asset.styles } };
    newAsset.styles.cssClass = newAsset.styles.class;
    newAsset.styles.bgColor = getMarkerColor(newAsset);

    let nodeZoomMultiplier = map.getZoom() > cableZoomTrigger ? cableZoomWeightMultiplier : 1;

    return iconMarker(newAsset, mode, activeTool, iconSize, nodeZoomMultiplier);
  };

  const getMarkerColor = (asset) => {
    switch (asset.rag?.toLowerCase()) {
      case "red":
        return colourBlindEnabled ? "colourblindRed" : "danger";
      case "amber":
        return colourBlindEnabled ? "colourblindAmber" : "warning";
      case "green":
        return colourBlindEnabled ? "colourblindGreen" : "success";
      default:
        return "three-phase";
    }
  };

  const getLineColor = (asset) => {
    switch (
      cascadingRAGColours ? asset.cascadingRAG?.toLowerCase() : asset.nonCascadingRAG?.toLowerCase()
    ) {
      case "red":
        return colourBlindEnabled ? color.COLOURBLIND_RED : color.RED;
      case "amber":
        return colourBlindEnabled ? color.COLOURBLIND_AMBER : color.AMBER;
      case "green":
        return colourBlindEnabled ? color.COLOURBLIND_GREEN : color.GREEN;
      default:
        return color.THREE_PHASE;
    }
  };

  const cableWeight = (asset) => {
    let weight = 2;

    if (!asset || !asset.cableGroup) return weight;

    if (asset.cableGroup === "mains-underground" || asset.cableGroup === "mains-overhead") {
      weight = 4;
    }

    // weight based on map zoom level
    if (map.getZoom() > cableZoomTrigger) {
      weight = weight * cableZoomWeightMultiplier;
    }

    return weight;
  };

  const cableDash = (asset, defaultDashLength, defaultSpacingLength) => {
    let dash = "";

    if (!asset || !asset.cableGroup || !defaultDashLength || !defaultSpacingLength) return dash;

    let dashLength = defaultDashLength;
    let spacingLength = defaultSpacingLength;

    if (map.getZoom() > cableZoomTrigger) {
      dashLength = dashLength * cableZoomWeightMultiplier;
      spacingLength = spacingLength * cableZoomWeightMultiplier;
    }

    if (asset.cableGroup.endsWith("underground")) {
      return `${dashLength} ${spacingLength}`;
    }

    return dash;
  };

  return (
    <>
      {asset.styles.tool === "Line" && (
        <Pane name="network-rag-cables" style={{ zIndex: 520 }}>
          <Polyline
            key={asset.id}
            id={asset.id}
            positions={asset.geometry}
            pathOptions={{
              weight: cableWeight(asset),
              dashArray: cableDash(asset, 4, 6),
              color: getLineColor(asset),
            }}
            snapIgnore={true}
          />
        </Pane>
      )}
      {asset.styles.tool === "Marker" && (
        <>
          <Marker
            id={asset.id}
            position={asset.geometry}
            icon={styleIcon({ ...asset, name: ragNetwork.substationName })}
            eventHandlers={{
              click: () => {
                onClick(asset);
              },
            }}
          />
        </>
      )}
    </>
  );
};

export default RagAsset;
