import { FormGroup, Label } from "reactstrap";
import { useContext } from "react";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";

const DynamicPropertiesList = ({ propertyType }) => {
  const { formState } = useContext(FormContext);
  const { clientSettings } = formState;
  const { toolState } = useContext(ToolContext);
  var properties = clientSettings.DynamicProperties?.sort(
    (a, b) => parseInt(a.SortOrder) - parseInt(b.SortOrder),
  );

  return (
    <div>
      {properties.map((item) => {
        if (item.PropertyType === propertyType) {
          return (
            <FormGroup>
              <Label className="id">
                {item.DisplayName}: {toolState.clickedAsset[item.AttributeValue]}
              </Label>
            </FormGroup>
          );
        } else {
          return <></>;
        }
      })}
    </div>
  );
};

export default DynamicPropertiesList;
