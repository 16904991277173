// @flow

import { v4 as uuid } from "uuid"; // generates GUIDs
import { Cable } from "../viewModel/cable";
import { Node } from "../viewModel/node";
import { Motor } from "../viewModel/motor";
import { Welder } from "../viewModel/welder";
import { ConnectionPoint } from "../viewModel/connectionPoint";
import { GroupedConnection } from "../viewModel/groupedConnection";
import { GroupedConnectionPoint } from "../viewModel/groupedConnectionPoint";
import { Transformer } from "../viewModel/transformer";
import { PointOfConnection } from "../viewModel/pointOfConnection";
import {
  getGeometryWktFromLineString,
  getGeometryWktFromPoint,
} from "../viewModel/geometryWktConverter";

import CimNetwork from "./cimNetwork";
import ConductingEquipment from "./conductingEquipment";
import ConnectivityNode from "./connectivityNode";
import Terminal from "./terminal";
import NameValueProperty from "./nameValueProperty";

type ConductingEquipmentType =
  | "ACLineSegment"
  | "EnergyConsumer"
  | "PowerTransformer"
  | "Junction"
  | "DistributedEnergyConsumer"
  | "PointOfConnection";

export const ACLineSegmentType = "ACLineSegment";
export const EnergyConsumerType = "EnergyConsumer";
export const MotorType = "Motor";
export const WelderType = "Welder";
export const DistributedEnergyConsumerType = "DistributedEnergyConsumer";
export const GeneratorConsumerType = "GeneratingUnit";
export const DistributedGeneratorConsumerType = "DistributedGeneratingUnit";
export const PowerTransformerType = "PowerTransformer";
export const JunctionType = "Junction";
export const PointOfConnectionType = "PointOfConnection";

export function createConductingEquipmentFromCable(
  cable: Cable,
  ratingType: string,
): ConductingEquipment {
  const type: ConductingEquipmentType = ACLineSegmentType;
  let properties = getNameValuePropertiesFrom(cable);
  updateGeometryPropertyToWktLine(properties);
  properties = setCableAutoSelectProperty(properties);
  properties = setRatingProperty(properties, ratingType);
  return new ConductingEquipment(cable.id, type, properties);
}

export function setCableAutoSelectProperty(properties: NameValueProperty[]) {
  const autoSelectPropertyName: string = "autoSelect";

  for (let [index, property] of Object.entries(properties)) {
    if (property.name === "cableType" && property.value) {
      const autoSelectProperty = properties.find((p) => p.name === autoSelectPropertyName);
      const isAutoSelect = property.value.toLowerCase().indexOf("auto") === 0;

      if (autoSelectProperty) {
        autoSelectProperty.value = isAutoSelect;
      } else {
        properties.push(new NameValueProperty(autoSelectPropertyName, isAutoSelect));
      }
    }
  }

  return properties;
}

export function setRatingProperty(properties: NameValueProperty[], ratingType: string) {
  const ratingPropertyName: string = "ratingType";

  const existingProperty = properties.find((p) => p.name === ratingPropertyName);
  if (existingProperty && !existingProperty.value) {
    existingProperty.value = ratingType;
  } else if (!existingProperty) {
    properties.push(new NameValueProperty(ratingPropertyName, ratingType));
  }

  return properties;
}

export function createCimNetwork(
  reference: any,
  cables: Cable[],
  nodes: Node[],
  connectionPoints: ConnectionPoint[],
  transformers: Transformer[],
  groupedConnections: GroupedConnection[],
  motors: Motor[],
  welders: Welder[],
  pointOfConnections: PointOfConnection[],
  ratingType: string,
) {
  const cimNetwork = new CimNetwork();

  nodes.forEach((node) => addNodeToCimNetwork(node, cimNetwork));
  connectionPoints.forEach((connectionPoint) =>
    addConnectionPointToCimNetwork(connectionPoint, cimNetwork),
  );
  motors.forEach((motor) => addMotorToCimNetwork(motor, cimNetwork));
  welders.forEach((welder) => addWelderToCimNetwork(welder, cimNetwork));
  groupedConnections.forEach((gc) => addGroupedConnectionPointToCimNetwork(gc, cimNetwork));
  transformers.forEach((transformer) =>
    addTransformerToCimNetwork(reference, transformer, cimNetwork, ratingType),
  );
  cables
    .filter((cable) => cable.length > 0 && cable.startAssetId !== cable.endAssetId)
    .forEach((cable) => {
      addDistributedConnectionPointToCimNetwork(cable, cimNetwork);
      addCableToCimNetwork(cable, cimNetwork, groupedConnections, ratingType);
    });
  if (pointOfConnections) {
    pointOfConnections.forEach((pointOfConnection) =>
      addPointOfConnectionToCimNetwork(pointOfConnection, cimNetwork),
    );
  }
  return cimNetwork;
}

function addTransformerToCimNetwork(
  reference: any,
  transformer: Transformer,
  cimNetwork: CimNetwork,
  ratingType: string,
): void {
  cimNetwork.conductingEquipment.push(
    createConductingEquipmentFromTransformer(transformer, ratingType),
  );

  //create virtual GroupedConnection
  if (transformer.groupedConnectionPoints && transformer.groupedConnectionPoints.length > 0) {
    let existingConsumerProperty = [new NameValueProperty("existingConsumer", true)];
    const connectivityNodeId = uuid();

    cimNetwork.connectivityNodes.push(
      new ConnectivityNode(connectivityNodeId, existingConsumerProperty),
    );

    const transformerGroupedConnectionPoints = [
      ...transformer.groupedConnectionPoints.map((originalGcp) => {
        const gcp = { ...originalGcp };
        gcp.existingConsumer = true;

        gcp.geometry = transformer.geometry;

        cimNetwork.terminals.push(
          new Terminal(gcp.id, connectivityNodeId, existingConsumerProperty),
        );

        gcp.subGroupConnectionPoints = gcp.subGroupConnectionPoints.map((originalSgcp) => {
          const sgcp = { ...originalSgcp };
          sgcp.existingConsumer = true;
          gcp.geometry = transformer.geometry;

          cimNetwork.terminals.push(
            new Terminal(sgcp.id, connectivityNodeId, existingConsumerProperty),
          );
          return sgcp;
        });

        return gcp;
      }),
    ];

    var groupedConnection = new GroupedConnection(
      transformer.id,
      transformer.geometry,
      transformerGroupedConnectionPoints,
      null,
      reference.groundTypeOverrideDefaults.groundTypeOverride,
    );

    addGroupedConnectionPointToCimNetwork(groupedConnection, cimNetwork);

    cimNetwork.terminals.push(
      new Terminal(transformer.id, connectivityNodeId, existingConsumerProperty),
    );
  }
}

//this is for distributed consumer, the input will be groupedConnecton
//but it will create connectionPoint rather than groupedConnectionPoint in cim
//it will differentiate normal connectionPoint by
//1. it has a branchId property, which linked back to cable
//2. The number of consumers will be set in phaseAutoConsumers

function addDistributedConnectionPointToCimNetwork(cable: Cable, cimNetwork: CimNetwork): void {
  cable.groupedConnectionPoints.forEach((gcp) => {
    cimNetwork.conductingEquipment.push(
      createDistributedConductingEquipmentFromConnectionPoint(gcp, cable.id),
    );
    gcp.subGroupConnectionPoints.forEach((sgcp) => {
      cimNetwork.conductingEquipment.push(
        createDistributedConductingEquipmentFromConnectionPoint(sgcp),
      );
    });
  });
}

function addMotorToCimNetwork(motor: Motor, cimNetwork: CimNetwork): void {
  cimNetwork.conductingEquipment.push(createConductingEquipmentFromMotor(motor));
}

function addWelderToCimNetwork(welder: Welder, cimNetwork: CimNetwork): void {
  cimNetwork.conductingEquipment.push(createConductingEquipmentFromWelder(welder));
}

function addPointOfConnectionToCimNetwork(
  pointOfConnection: PointOfConnection,
  cimNetwork: CimNetwork,
): void {
  cimNetwork.conductingEquipment.push(
    createConductingEquipmentFromPointOfConnection(pointOfConnection),
  );
}

function addConnectionPointToCimNetwork(
  connectionPoint: ConnectionPoint,
  cimNetwork: CimNetwork,
): void {
  cimNetwork.conductingEquipment.push(
    createConductingEquipmentFromConnectionPoint(connectionPoint),
  );
}

function addGroupedConnectionPointToCimNetwork(
  groupedConnection: GroupedConnection,
  cimNetwork: CimNetwork,
): void {
  groupedConnection.groupedConnectionPoints.forEach((originalGcp) => {
    const gcp = { ...originalGcp };
    //populate gcp parentid
    gcp.parentId = groupedConnection.id;
    gcp.annotation = groupedConnection.annotation;
    gcp.multiOccupancyContainer = JSON.stringify(groupedConnection.multiOccupancyContainer);
    cimNetwork.conductingEquipment.push(createConductingEquipmentFromConnectionPoint(gcp));
    gcp.subGroupConnectionPoints.forEach((originalSgcp) => {
      const sgcp = { ...originalSgcp };
      //set phasing to be same as parent
      sgcp.numberOfPhases = gcp.numberOfPhases;
      sgcp.autoPhase = gcp.autoPhase;
      sgcp.phase1Consumers = gcp.phase1Consumers;
      sgcp.phase2Consumers = gcp.phase2Consumers;
      sgcp.phase3Consumers = gcp.phase3Consumers;
      sgcp.balancedLoad = gcp.balancedLoad;
      sgcp.unbalancePercent = gcp.unbalancePercent;
      sgcp.lowLoadedPhase = gcp.lowLoadedPhase;

      cimNetwork.conductingEquipment.push(createConductingEquipmentFromConnectionPoint(sgcp));
    });
  });
}

function addNodeToCimNetwork(node: Node, cimNetwork: CimNetwork): void {
  cimNetwork.conductingEquipment.push(createConductingEquipmentFromNode(node));
}

function connectGroupedConnection(
  cimNetwork: CimNetwork,
  groupedConnection: GroupedConnection,
  connectivityNodeId: string,
): void {
  groupedConnection.groupedConnectionPoints.forEach((gcp) =>
    connectGroupedConnectionPoint(cimNetwork, gcp, connectivityNodeId),
  );
}

function connectGroupedConnectionPoint(
  cimNetwork: CimNetwork,
  groupedConnectionPoint: GroupedConnectionPoint,
  connectivityNodeId: string,
) {
  cimNetwork.terminals.push(new Terminal(groupedConnectionPoint.id, connectivityNodeId));

  groupedConnectionPoint.subGroupConnectionPoints.forEach((sgcp) =>
    cimNetwork.terminals.push(new Terminal(sgcp.id, connectivityNodeId)),
  );
}

function connectDistributedConnection(cable: Cable, cimNetwork: CimNetwork): void {
  //get groupedConnectionPoints of the cable
  let distributedGCP = cimNetwork.conductingEquipment.filter(
    (ce) =>
      (ce.type === DistributedEnergyConsumerType || ce.type === DistributedGeneratorConsumerType) &&
      ce.getPropertyByName("parentId") &&
      ce.getPropertyByName("parentId").value === cable.id,
  );

  if (distributedGCP && distributedGCP.length > 0) {
    const cableConnectivityNodeId = uuid();

    cimNetwork.connectivityNodes.push(new ConnectivityNode(cableConnectivityNodeId));

    cimNetwork.terminals.push(new Terminal(cable.id, cableConnectivityNodeId));

    distributedGCP.forEach((ce) => {
      cimNetwork.terminals.push(new Terminal(ce.id, cableConnectivityNodeId));

      let distributedSubGCP = cimNetwork.conductingEquipment.filter(
        (sce) =>
          (sce.type === DistributedEnergyConsumerType ||
            sce.type === DistributedGeneratorConsumerType) &&
          sce.getPropertyByName("parentId") &&
          sce.getPropertyByName("parentId").value === ce.id,
      );

      distributedSubGCP.forEach((sce) =>
        cimNetwork.terminals.push(new Terminal(sce.id, cableConnectivityNodeId)),
      );
    });
  }
}

export function addCableToCimNetwork(
  cable: Cable,
  cimNetwork: CimNetwork,
  groupedConnections: GroupedConnection[],
  ratingType: string,
): CimNetwork {
  // create acLineSegment
  const acLineSegment = createConductingEquipmentFromCable(cable, ratingType);
  cimNetwork.conductingEquipment.push(acLineSegment);

  // connect at start
  const startConnectivityNodeId = uuid();
  cimNetwork.connectivityNodes.push(new ConnectivityNode(startConnectivityNodeId));

  const groupedConnectionAtStart = groupedConnections.find(
    (element) =>
      element.id === cable.startAssetId ||
      (element.groupedConnectionPoints &&
        element.groupedConnectionPoints.some((e) => e.id === cable.startAssetId)),
  );

  if (groupedConnectionAtStart == undefined) {
    cimNetwork.terminals.push(new Terminal(cable.startAssetId, startConnectivityNodeId));
  } else {
    connectGroupedConnection(cimNetwork, groupedConnectionAtStart, startConnectivityNodeId);
  }

  cimNetwork.terminals.push(new Terminal(acLineSegment.id, startConnectivityNodeId));

  // connect at end
  const endConnectivityNodeId = uuid();
  cimNetwork.connectivityNodes.push(new ConnectivityNode(endConnectivityNodeId));

  const groupedConnectionAtEnd = groupedConnections.find(
    (element) => element.id === cable.endAssetId,
  );

  if (groupedConnectionAtEnd == undefined) {
    cimNetwork.terminals.push(new Terminal(cable.endAssetId, endConnectivityNodeId));
  } else {
    connectGroupedConnection(cimNetwork, groupedConnectionAtEnd, endConnectivityNodeId);
  }

  cimNetwork.terminals.push(new Terminal(acLineSegment.id, endConnectivityNodeId));

  connectDistributedConnection(cable, cimNetwork);

  return cimNetwork;
}

export function createConductingEquipmentFromTransformer(
  transformer: Transformer,
  ratingType: string,
): ConductingEquipment {
  const type: ConductingEquipmentType = PowerTransformerType;
  let properties = getNameValuePropertiesFrom(transformer);
  updateGeometryPropertyToWktPoint(properties);
  properties = setTransformerAutoSelectProperty(properties);
  properties = setRatingProperty(properties, ratingType);
  return new ConductingEquipment(transformer.id, type, properties);
}

function updateGeometryPropertyToWktLine(properties: NameValueProperty[]) {
  properties.forEach((property) => {
    if (property.name === "geometry" && Array.isArray(property.value)) {
      property.value = getGeometryWktFromLineString(property.value);
    }
  });
}

function updateGeometryPropertyToWktPoint(properties: NameValueProperty[]) {
  properties.forEach((property) => {
    if (property.name === "geometry" && property.value) {
      property.value = getGeometryWktFromPoint(property.value);
    }
  });
}

export function setTransformerAutoSelectProperty(properties: NameValueProperty[]) {
  const autoSelectPropertyName: string = "autoSelect";

  for (let [index, property] of Object.entries(properties)) {
    if (property.name === "nameplateRating" && property.value) {
      const autoSelectProperty = properties.find((p) => p.name === autoSelectPropertyName);

      const isAutoSelect = isNaN(property.value);

      if (autoSelectProperty) {
        autoSelectProperty.value = isAutoSelect;
      } else {
        properties.push(new NameValueProperty(autoSelectPropertyName, isAutoSelect));
      }
    }
  }

  return properties;
}

export function createConductingEquipmentFromNode(node: Node): ConductingEquipment {
  const type: ConductingEquipmentType = JunctionType;
  const properties = getNameValuePropertiesFrom(node);
  updateGeometryPropertyToWktPoint(properties);

  return new ConductingEquipment(node.id, type, properties);
}

export function createConductingEquipmentFromMotor(motor: Motor): ConductingEquipment {
  const type: ConductingEquipmentType = MotorType;
  const properties = getNameValuePropertiesFrom(motor);
  updateGeometryPropertyToWktPoint(properties);
  return new ConductingEquipment(motor.id, type, properties);
}

export function createConductingEquipmentFromWelder(welder: Welder): ConductingEquipment {
  const type: ConductingEquipmentType = WelderType;
  const properties = getNameValuePropertiesFrom(welder);
  updateGeometryPropertyToWktPoint(properties);
  return new ConductingEquipment(welder.id, type, properties);
}

export function createConductingEquipmentFromPointOfConnection(
  pointOfConnection: PointOfConnection,
): ConductingEquipment {
  const type: ConductingEquipmentType = PointOfConnectionType;
  const properties = getNameValuePropertiesFrom(pointOfConnection);
  updateGeometryPropertyToWktPoint(properties);
  return new ConductingEquipment(pointOfConnection.id, type, properties);
}

export function createConductingEquipmentFromConnectionPoint(
  connectionPoint: ConnectionPoint,
  isDistributedEnergyConsumer: Boolean,
): ConductingEquipment {
  const type: ConductingEquipmentType = connectionPoint.isGenerator
    ? isDistributedEnergyConsumer
      ? DistributedGeneratorConsumerType
      : GeneratorConsumerType
    : isDistributedEnergyConsumer
      ? DistributedEnergyConsumerType
      : EnergyConsumerType;
  const properties = getNameValuePropertiesFrom(connectionPoint);
  updateGeometryPropertyToWktPoint(properties);
  return new ConductingEquipment(connectionPoint.id, type, properties);
}

export function createDistributedConductingEquipmentFromConnectionPoint(
  originalConnectionPoint: ConnectionPoint,
  branchId: Number,
): ConductingEquipment {
  const connectionPoint = { ...originalConnectionPoint };

  if (branchId && connectionPoint.isSubGroupConnection !== true) {
    connectionPoint.parentId = branchId;
  }
  const count = parseInt(connectionPoint.count);

  connectionPoint.phase1Consumers = 0;
  connectionPoint.phase2Consumers = 0;
  connectionPoint.phase3Consumers = 0;
  connectionPoint.phaseAutoConsumers = count;

  let conductingEquipment = createConductingEquipmentFromConnectionPoint(connectionPoint, true);

  const phaseAutoConsumers = conductingEquipment.getPropertyByName("phaseAutoConsumers");

  const phase1Consumers = conductingEquipment.getPropertyByName("phase1Consumers");

  const phase2Consumers = conductingEquipment.getPropertyByName("phase2Consumers");

  const phase3Consumers = conductingEquipment.getPropertyByName("phase3Consumers");

  phaseAutoConsumers.value =
    phaseAutoConsumers.value +
    phase1Consumers.value +
    phase2Consumers.value +
    phase3Consumers.value;

  phase1Consumers.value = 0;
  phase2Consumers.value = 0;
  phase3Consumers.value = 0;

  return conductingEquipment;
}

function getNameValuePropertiesFrom(object): NameValueProperty[] {
  const properties = [];
  for (let [name, value] of Object.entries(object)) {
    if (name && name.endsWith("IsDefault") && value === true) {
      value = false;
    }
    if (name === "styles") {
      if (value) {
        const property = new NameValueProperty("class", value.class);
        properties.push(property);
      }
    } else if (name === "linkBox") {
      const property = new NameValueProperty(name, JSON.stringify(value));
      properties.push(property);
    } else if (name === "pole") {
      const property = new NameValueProperty(name, JSON.stringify(value));
      properties.push(property);
    } else if (
      Array.isArray(value) &&
      (name.endsWith("ConnectionPoints") || name === "phaseMap" || name === "potEnds")
    ) {
      if (name === "phaseMap" && value) {
        value = value.map((phaseNumber) => {
          if (phaseNumber === null || phaseNumber === undefined) phaseNumber = 0;
          return phaseNumber;
        });
      }
      const property = new NameValueProperty(name, JSON.stringify(value));
      properties.push(property);
    } else if (value !== undefined && value !== null) {
      if (name === "overrideGroundType" || name === "multiOccupancyContainer") {
        const propertyValue = value instanceof Object ? JSON.stringify(value) : value;
        const property = new NameValueProperty(name, propertyValue);

        properties.push(property);
      } else if (name === "groundTypeOverrides") {
        const canParse = value.length > 0 && value.some((p) => p.length);

        const propertyValue =
          value instanceof Object
            ? JSON.stringify(
                canParse ? value.map((p) => ({ ...p, length: parseFloat(p.length) })) : value,
              )
            : value;
        const property = new NameValueProperty(name, propertyValue);

        properties.push(property);
      } else {
        const property = new NameValueProperty(name, value);
        properties.push(property);
      }
    }
  }

  return properties;
}
