import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormGroup, Label, Input, Col, Button } from "reactstrap";
import { updateMotor, findMotor, clearResults } from "../app/networkSlice";

import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";

import RadioBtnGrp from "./RadioBtnGrp";
import DataAlert from "./DataAlert";
import { getResultProperties } from "../utils/referenceFunctions";
import { store } from "../app/store";
import { endBatchAction, startBatchAction } from "../app/undoable";
import DynamicPropertiesList from "../components/DynamicPropertiesList";

const phasesObj = {
  name: "phases",
  obj: [
    { id: "motorSinglePhase", value: "Single", color: "light" },
    { id: "motorTwoPhase", value: "Two", color: "light" },
    { id: "motorThreePhase", value: "Three", color: "light" },
  ],
};

const MotorForm = ({ motor }) => {
  const { formState, dispatch } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const { clientSettings, reference } = formState;
  const dispatchRedux = useDispatch();

  const { errors, clickedAsset } = toolState;

  const activeMotor = useSelector((state) => findMotor(state, clickedAsset?.id));

  const {
    rating,
    annotation,
    ratingIsDefault,
    ratingUnit,
    numberOfPhases,
    numberOfPhasesIsDefault,
    starter,
    starterIsDefault,
    startingPowerFactor,
    startingPowerFactorIsDefault,
    numberOfStarts,
    numberOfStartsIsDefault,
    numberOfStartsUnit,
    efficiency,
    efficiencyIsDefault,
    startingCurrentOverride,
  } = clickedAsset ? activeMotor : motor;

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const defaultsArr = [
    { name: numberOfPhasesIsDefault, label: "numberOfPhases" },
    { name: ratingIsDefault, label: "rating" },
    { name: starterIsDefault, label: "starter" },
    { name: numberOfStartsIsDefault, label: "numberOfStarts" },
    { name: startingPowerFactorIsDefault, label: "startingPowerFactor" },
    { name: efficiencyIsDefault, label: "efficiency" },
  ];

  const changeInputValue = (e) => {
    const name = e.name ? e.name : e.target.name;
    const value = e.value || e.value === 0 ? e.value : e.target.value;

    store.dispatch(startBatchAction());
    try {
      handleDefault(name);
      dispatch({
        form: "motorProperties",
        field: name,
        value: value,
        type: "UPDATE_FIELD",
      });
      if (clickedAsset) {
        const filteredErrors = errors.messages
          ? errors.messages.map((message) => message.link)
          : [];

        if (filteredErrors.includes(clickedAsset.id)) {
          let _errors = { ...errors };
          _errors.messages = _errors.messages
            ? _errors.messages.filter((message) => message.link !== clickedAsset.id)
            : [];

          const _toolState = toolState;
          _toolState.errors = _errors;
          setToolState(_toolState);
        }

        dispatchRedux(updateMotor({ id: clickedAsset.id, name, value }));
      }
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const hideResults = () => {
    const _toolState = toolState;
    _toolState.showResults = false;
    setToolState(_toolState);
    dispatchRedux(clearResults(getResultProperties(reference)));
  };

  const handleChange = (e) => {
    hideResults();
    changeInputValue(e);
  };

  const setSelectedPhase_ = (e) => {
    hideResults();
    changeInputValue({ name: "numberOfPhases", value: e.value });
  };

  const handleDefault = (e) => {
    if (defaultsArr.some((d) => d.label === e)) {
      dispatch({
        form: "motorProperties",
        field: `${e}IsDefault`,
        value: false,
        type: "UPDATE_FIELD",
      });
      if (clickedAsset) {
        dispatchRedux(updateMotor({ id: clickedAsset.id, name: `${e}IsDefault`, value: false }));
      }
    }
  };

  const handleAllDefaults = () => {
    store.dispatch(startBatchAction());
    try {
      defaultsArr.forEach((d) => {
        if (d.name) handleDefault(d.label);
      });
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  return (
    <Form
      className="w-100 text-light"
      style={{
        padding: ".75rem",
      }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <FormGroup>
        <Input
          id="annotation"
          name="annotation"
          className="w-100"
          placeholder="Enter label/annotation"
          value={annotation}
          onChange={handleChange}
          maxLength="50"
          autoFocus
        />
      </FormGroup>
      {defaultsArr.find((d) => d.name === true) && (
        <Button color="warning" outline block className="mb-3" onClick={() => handleAllDefaults()}>
          Resolve all default warnings
        </Button>
      )}
      {clickedAsset && (
        <span className="motorId" style={{ position: "absolute", visibility: "hidden" }}>
          {clickedAsset.id}
        </span>
      )}
      {clickedAsset && clientSettings.Features.DynamicPropertiesEnabled && (
        <DynamicPropertiesList propertyType={"EnergyConsumer"} />
      )}

      {clickedAsset && clickedAsset.nodeNumber && (
        <FormGroup>
          <Label className="id">ID: {clickedAsset.nodeNumber}</Label>
        </FormGroup>
      )}
      <FormGroup>
        <Label for="phases">
          No. of Phases
          {numberOfPhasesIsDefault && (
            <DataAlert updateDefault={() => handleDefault("numberOfPhases")} />
          )}
        </Label>
        <RadioBtnGrp
          items={phasesObj}
          changeValue={setSelectedPhase_}
          selectedValue={numberOfPhases}
        />
      </FormGroup>

      <FormGroup row>
        <Col sm={6}>
          <Label for="rating">
            Rating
            {ratingIsDefault && <DataAlert updateDefault={() => handleDefault("rating")} />}
          </Label>
          <Input
            type="number"
            id="rating"
            name="rating"
            value={rating}
            min="0"
            onChange={handleChange}
          />
        </Col>
        <Col sm={6}>
          <Label for="ratingUnit">Unit</Label>
          <Input
            type="select"
            name="ratingUnit"
            id="ratingUnit"
            value={ratingUnit}
            onChange={handleChange}
          >
            {reference.motors.ratingUnits.map((unit) => (
              <option key={unit.value} value={unit.value}>
                {unit.name}
              </option>
            ))}
          </Input>
        </Col>
      </FormGroup>

      <FormGroup>
        <Label for="consumerType">
          Starter
          {starterIsDefault && <DataAlert updateDefault={() => handleDefault("starter")} />}
        </Label>
        <Input type="select" name="starter" id="starter" value={starter} onChange={handleChange}>
          {reference.motors.starters.map((starter) => (
            <option key={starter.value} value={starter.value}>
              {starter.name}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup row>
        <Col sm={6}>
          <Label for="numberOfStarts">
            Number of Starts
            {numberOfStartsIsDefault && (
              <DataAlert updateDefault={() => handleDefault("numberOfStarts")} />
            )}
          </Label>
          <Input
            type="number"
            id="numberOfStarts"
            name="numberOfStarts"
            value={numberOfStarts}
            min="0"
            onChange={handleChange}
          />
        </Col>
        <Col sm={6}>
          <Label for="numberOfStartsUnit">Unit</Label>
          <Input
            type="select"
            name="numberOfStartsUnit"
            id="numberOfStartsUnit"
            value={numberOfStartsUnit}
            onChange={handleChange}
          >
            {reference.motors.numberOfStartsUnits.map((unit) => (
              <option key={unit.value} value={unit.value}>
                {unit.name}
              </option>
            ))}
          </Input>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col sm={6}>
          <Label for="startingPowerFactor">
            Starting Power Factor
            {startingPowerFactorIsDefault && (
              <DataAlert updateDefault={() => handleDefault("startingPowerFactor")} />
            )}
          </Label>
          <Input
            type="number"
            step="0.1"
            id="startingPowerFactor"
            name="startingPowerFactor"
            value={startingPowerFactor}
            min="0"
            onChange={handleChange}
          />
        </Col>
        <Col sm={6}>
          <Label for="startingCurrentOverride">Starting Current Override</Label>
          <Input
            type="number"
            step="0.1"
            id="startingCurrentOverride"
            name="startingCurrentOverride"
            value={startingCurrentOverride}
            min="0"
            onChange={handleChange}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col sm={6}>
          <Label for="efficiency">
            Efficiency (%)
            {efficiencyIsDefault && <DataAlert updateDefault={() => handleDefault("efficiency")} />}
          </Label>
          <Input
            type="number"
            step="0.01"
            id="efficiency"
            name="efficiency"
            value={efficiency}
            min="0"
            max="100"
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
    </Form>
  );
};

export default MotorForm;
