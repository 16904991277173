import { updateCable } from "../../app/networkSlice";
import * as statusConstants from "../../constants/status";

export const shouldShowCableTypeForm = (ringfencedTypes, selected) => {
  const shouldShow = (type) => {
    switch (type) {
      case "Mains Underground Cable":
      case "Service Underground Cable":
      case "Mains Overhead Line":
      case "Service Overhead Line":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    const isNotAllowed =
      ringfencedTypes.length > 1 || ringfencedTypes.some((p) => !shouldShow(p.name));
    return !isNotAllowed;
  }
  if (selected === "Conductors") {
    return ringfencedTypes.filter((p) => shouldShow(p.name)).length === 1;
  }
  return shouldShow(selected);
};

export const refreshCableTypeWarning = (
  ringfencedFiltered,
  ohWhitelist,
  ugWhitelist,
  cableType,
  cableTypeWarning,
  setCableTypeWarning,
  status,
) => {
  if (
    !ohWhitelist.includes(cableType) &&
    ringfencedFiltered.some(
      (p) =>
        p.cableGroup &&
        p.cableGroup.split("-")[1] === "overhead" &&
        (status ?? p.status) !== statusConstants.EXISTING,
    )
  ) {
    setCableTypeWarning(true);
  } else if (
    !ugWhitelist.includes(cableType) &&
    ringfencedFiltered.some(
      (p) =>
        p.cableGroup &&
        p.cableGroup.split("-")[1] === "underground" &&
        (status ?? p.status) !== statusConstants.EXISTING,
    )
  ) {
    setCableTypeWarning(true);
  } else if (cableTypeWarning) {
    setCableTypeWarning(false);
  }
};

export const applyCableTypeChange = (ringfencedFiltered, cableType, dispatchRedux) => {
  ringfencedFiltered.forEach((p) => {
    dispatchRedux(updateCable({ id: p.id, name: "cableType", value: cableType }));
    dispatchRedux(
      updateCable({
        id: p.id,
        name: "autoSelect",
        value: cableType === "auto" ? true : false,
      }),
    );
    dispatchRedux(updateCable({ id: p.id, name: "cableTypeIsDefault", value: false }));
  });
};
