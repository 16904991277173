import { Button, Input, InputGroup } from "reactstrap";

const TableHeader = ({
  page,
  column,
  sort,
  sortColumn,
  sortColumnAsc,
  optionSearchValue,
  setOptionSearchValue,
}) => {
  const index = page.columns.indexOf(column);
  const sortIcon =
    page.rows.length > 1 && index === sortColumn ? (
      <i className={`icon-caret-${sortColumnAsc ? "up" : "down"}-solid`} />
    ) : null;

  const filterSearch = (e) => {
    const value = e.value !== undefined ? e.value : e.target.value;
    const o = { ...optionSearchValue };
    o[index] = value;
    setOptionSearchValue(o);
  };

  const s =
    page.rows.length > 1 ? (
      <InputGroup>
        <Input
          type="text"
          placeholder="Search"
          bsSize="sm"
          onChange={filterSearch}
          value={optionSearchValue[index]}
          onClick={(e) => e.stopPropagation()}
        ></Input>
        <Button
          className="input-button"
          onClick={(e) => {
            if (optionSearchValue[index]?.length > 0) {
              const o = { ...optionSearchValue };
              o[index] = "";
              setOptionSearchValue(o);
            }
            e.stopPropagation();
          }}
        >
          <i
            className={`icon-${optionSearchValue[index]?.length > 0 ? "cross" : "search"}-solid`}
          ></i>
        </Button>
      </InputGroup>
    ) : null;

  return (
    <th onClick={() => sort(page.columns.indexOf(column))}>
      {column.displayName}&nbsp;{sortIcon}
      {s}
    </th>
  );
};

export default TableHeader;
