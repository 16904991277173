import { Table } from "reactstrap";
import { useEffect, useState } from "react";
import TableHeader from "./ConfigTableHeader";

const ConfigTab = ({ page, theme }) => {
  const [sortColumn, setSortColumn] = useState(-1);
  const [sortColumnAsc, setSortColumnAsc] = useState(true);

  const [optionSearchValue, setOptionSearchValue] = useState({});

  useEffect(() => {
    setSortColumn(-1);
    setSortColumnAsc(true);
  }, [page.title]);

  const sort = (columnName) => {
    if (sortColumn === columnName) {
      setSortColumnAsc(!sortColumnAsc);
    } else {
      setSortColumn(columnName);
      setSortColumnAsc(true);
    }
  };

  const orderedRows = [...page.rows];
  if (sortColumn !== -1) {
    orderedRows.sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];
      if (!sortColumnAsc) {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      }
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    });
  }

  const filteredRows = orderedRows.filter((p) => {
    return p.every((q, i) => {
      const search = optionSearchValue[i];
      if (search === undefined || search.trim() === "") {
        return true;
      }
      if (q?.toString()?.toLowerCase()?.includes(search.toLowerCase())) {
        return true;
      }
      return false;
    });
  });

  return (
    <div>
      <h5 className="mb-4">{page.title}</h5>
      <Table className={`table-sm table-custom table-custom-${theme} table-sortable`}>
        <thead>
          <tr className="mb-4">
            {page.columns.map((column) => (
              <TableHeader
                key={column.displayName}
                column={column}
                page={page}
                sort={sort}
                sortColumn={sortColumn}
                sortColumnAsc={sortColumnAsc}
                optionSearchValue={optionSearchValue}
                setOptionSearchValue={setOptionSearchValue}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredRows.map((row, rowIndex) => (
            <tr key={`${rowIndex}_Row`}>
              {row.map((cell, cellindex) => (
                <td key={`${cellindex}_Cell`}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ConfigTab;
