import {
  getAssetById,
  nodeIsConnectedToOverheadLine,
  nodeIsConnectedToUndergroundLine,
  nodeIsConnectedToExistingOverheadLine,
} from "../utils/networkFunctions";
import { formatLengthPrecision } from "../utils/numberFunctions";
import * as status from "../constants/status";

import {
  updateGroupedConnection,
  updateGroupedConnectionPole,
  updateTransformer,
} from "../app/networkSlice";

function getDefaultPoleTermLength(clientSettings, reference, hasUnderground) {
  return formatLengthPrecision(
    clientSettings.Features.PoleTermLengthEnabled && hasUnderground
      ? reference.pole.defaults.poleTermLength
      : 0,
  );
}

export const getDefaultPole = (
  clientSettings,
  reference,
  cables,
  asset,
  enabled,
  hasUnderground,
  manualStatus,
) => {
  if (hasUnderground === undefined) {
    hasUnderground = nodeIsConnectedToUndergroundLine(cables, asset);
  }

  const existingPole = asset?.pole ? { ...asset.pole } : undefined;

  // If pole is to be disabled and the pole already exists, update the existing pole and reset the pole term length
  if (!enabled && asset && existingPole) {
    existingPole.enabled = false;
    existingPole.isDefaultPoleTermLength = true;
    existingPole.poleTermLength = formatLengthPrecision(0);
  }

  // If there's a pole, and it's enabled, return the pole
  if (asset && existingPole && existingPole.enabled) {
    // Check that there are still underground cables connected to the pole - if not, reset the pole term length
    if (!hasUnderground) {
      existingPole.isDefaultPoleTermLength = true;
      existingPole.poleTermLength = formatLengthPrecision(0);
    }
    return existingPole;
  }

  let isDefaultPoleTermLength = true;
  let poleTermLength = getDefaultPoleTermLength(clientSettings, reference, hasUnderground);

  // Check if there's a custom pole term length set for the current pole, and if it exists, use it
  if (
    asset &&
    asset.pole &&
    !asset.pole.enabled &&
    !asset.pole.isDefaultPoleTermLength &&
    hasUnderground
  ) {
    poleTermLength = asset?.pole?.poleTermLength;
    isDefaultPoleTermLength = false;
  }

  return {
    enabled: enabled,
    poleHeight: reference.pole.defaults.poleHeight,
    stayAngle: reference.pole.defaults.stayAngle,
    status:
      manualStatus ??
      (nodeIsConnectedToExistingOverheadLine(cables, asset) ? status.EXISTING : status.NEW),
    poleTermLength: poleTermLength,
    isDefaultPoleTermLength: isDefaultPoleTermLength,
  };
};

export const createPoles = (
  clientSettings,
  assets,
  newNodeId,
  cables,
  dispatchRedux,
  reference,
  hasOverhead,
  hasUnderground,
  isSplitCable,
) => {
  assets.forEach((a) =>
    createPole(
      dispatchRedux,
      clientSettings,
      a,
      newNodeId,
      cables,
      reference,
      hasOverhead,
      hasUnderground,
      isSplitCable,
    ),
  );
};
export const createPole = (
  dispatchRedux,
  clientSettings,
  node,
  newNodeId,
  cables,
  reference,
  hasOverhead,
  hasUnderground,
  isSplitCable,
) => {
  // If the node can't be found, don't create a pole
  if (!node) {
    return false;
  }

  // The overhead/underground values are usually provided by the calling code (in cases where the updated network hasn't been stored in state yet), but if not, populate them here
  if (hasOverhead === undefined) {
    hasOverhead = nodeIsConnectedToOverheadLine(cables, node);
  }
  if (hasUnderground === undefined) {
    hasUnderground = nodeIsConnectedToUndergroundLine(cables, node);
  }

  if (node.id !== newNodeId && hasOverhead && isSplitCable) return false;

  if (node.pole && node.pole.enabled) {
    // If there's an existing, enabled pole but there are no underground cables connected to it, create a new pole with a reset pole term length
    if (!hasUnderground) {
      const pole = {
        enabled: true,
        poleHeight: reference.pole.defaults.poleHeight,
        stayAngle: reference.pole.defaults.stayAngle,
        status: isSplitCable
          ? node.pole.status
          : nodeIsConnectedToExistingOverheadLine(cables, node)
            ? status.EXISTING
            : status.NEW,
        poleTermLength: formatLengthPrecision(0),
        isDefaultPoleTermLength: true,
        poleLink: node.pole.poleLink,
        poleLinkEnabled: node.pole.poleLinkEnabled,
      };
      dispatchRedux(updateGroupedConnection({ id: node.id, name: "pole", value: pole }));
      return true;
    }

    if (hasOverhead) {
      // If there's an existing, enabled pole using the default length and there's at least one overhead cable connected to it create a new pole with the default pole term length
      if (node.pole.isDefaultPoleTermLength) {
        const pole = {
          enabled: true,
          poleHeight: node.pole.poleHeight,
          stayAngle: node.pole.stayAngle,
          status: isSplitCable
            ? node.pole.status
            : nodeIsConnectedToExistingOverheadLine(cables, node)
              ? status.EXISTING
              : status.NEW,
          poleTermLength: getDefaultPoleTermLength(clientSettings, reference, hasUnderground),
          isDefaultPoleTermLength: true,
          poleLink: node.pole.poleLink,
          poleLinkEnabled: node.pole.poleLinkEnabled,
        };
        dispatchRedux(updateGroupedConnection({ id: node.id, name: "pole", value: pole }));
        return true;
      }
      // Otherwise keep the pole as it is
      return false;
    } else {
      // If there's a node with no overhead cables, create a new disabled pole
      const pole = {
        enabled: false,
        poleHeight: reference.pole.defaults.poleHeight,
        stayAngle: reference.pole.defaults.stayAngle,
        status: status.NEW,
        poleTermLength: formatLengthPrecision(0),
        isDefaultPoleTermLength: true,
      };
      dispatchRedux(updateGroupedConnection({ id: node.id, name: "pole", value: pole }));
      return true;
    }
  }

  // Check if the node is a pole-mounted transformer
  if (node.mounting === "pmt") {
    // If it is, and there's an existing pole term length property set don't create a pole
    if (node.poleTermLength !== undefined && node.isDefaultPoleTermLength === false) {
      return false;
    }

    let newPoleTermLength;
    // If it's got an underground cable, set to be the default length
    if (hasUnderground) {
      newPoleTermLength = getDefaultPole(
        clientSettings,
        reference,
        cables,
        node,
        true,
        hasUnderground,
      ).poleTermLength;
    } else {
      // Otherwise reset to 0
      newPoleTermLength = formatLengthPrecision(0);
    }

    dispatchRedux(
      updateTransformer({
        id: node.id,
        name: "poleTermLength",
        value: newPoleTermLength,
      }),
    );
    dispatchRedux(
      updateTransformer({
        id: node.id,
        name: "isDefaultPoleTermLength",
        value: true,
      }),
    );

    return true;
  }

  // If there's no overhead cables, or it's a ground-mounted transformer, don't create a pole
  if (!hasOverhead || node.mounting === "gmt") {
    return false;
  }

  // Create the new pole
  const pole = getDefaultPole(
    clientSettings,
    reference,
    cables,
    node,
    true,
    hasUnderground,
    isSplitCable ? status.NEW : undefined,
  );
  dispatchRedux(updateGroupedConnection({ id: node.id, name: "pole", value: pole }));
  return true;
};

export const removePoles = (assetIds, allAssets, dispatchRedux, hasOverhead, hasUnderground) => {
  assetIds.forEach((a) => removePole(a, allAssets, hasOverhead, hasUnderground, dispatchRedux));
};

export const removePole = (assetId, allAssets, hasOverhead, hasUnderground, dispatchRedux) => {
  const node = getAssetById(allAssets, assetId);

  // If the node doesn't exist, or the pole doesn't exist or the pole is already disabled, don't do anything
  if (!node || !node.pole || !node.pole.enabled) {
    return false;
  }

  // If there's an overhead cable, only reset the pole term length instead of removing the pole
  if (hasOverhead) {
    // If the pole doesn't have any underground cables, reset the pole term length
    if (!hasUnderground) {
      dispatchRedux(
        updateGroupedConnectionPole({
          id: node.id,
          name: "poleTermLength",
          value: formatLengthPrecision(0),
        }),
      );
      dispatchRedux(
        updateGroupedConnectionPole({
          id: node.id,
          name: "isDefaultPoleTermLength",
          value: true,
        }),
      );
    }
    return true;
  }

  dispatchRedux(updateGroupedConnectionPole({ id: node.id, name: "enabled", value: false }));
  return true;
};

export const removePolesForCable = (cableId, allAssets, cables, dispatchRedux) => {
  const cable = cables.find((p) => p.id === cableId);
  if (!cable) {
    return;
  }

  removePoles(
    [cable.startAssetId],
    allAssets,
    dispatchRedux,
    nodeIsConnectedToOverheadLine(cables, { id: cable.startAssetId }, cableId),
    nodeIsConnectedToUndergroundLine(cables, { id: cable.startAssetId }, cableId),
  );

  removePoles(
    [cable.endAssetId],
    allAssets,
    dispatchRedux,
    nodeIsConnectedToOverheadLine(cables, { id: cable.endAssetId }, cableId),
    nodeIsConnectedToUndergroundLine(cables, { id: cable.endAssetId }, cableId),
  );
};

export const refreshPolesForCable = (
  clientSettings,
  assetIds,
  cables,
  allAssets,
  dispatchRedux,
  reference,
  currentCableType,
  currentCableId,
) => {
  assetIds.forEach((assetId) =>
    refreshPolesForNode(
      clientSettings,
      assetId,
      cables,
      allAssets,
      dispatchRedux,
      reference,
      currentCableType,
      currentCableId,
    ),
  );
};

export const refreshPolesForNode = (
  clientSettings,
  assetId,
  cables,
  allAssets,
  dispatchRedux,
  reference,
  currentCableType,
  currentCableId,
) => {
  // Check if the node is connected to an overhead cable, or the new cable is overhead
  let hasOverhead =
    nodeIsConnectedToOverheadLine(cables, { id: assetId }, currentCableId) ||
    currentCableType.endsWith("overhead") ||
    currentCableType === "pmt";

  if (!hasOverhead) {
    const asset = getAssetById(allAssets, assetId);
    hasOverhead = asset?.mounting === "pmt";
  }

  // Check if the node is connected to an underground cable, or the new cable is underground
  const hasUnderground =
    nodeIsConnectedToUndergroundLine(cables, { id: assetId }, currentCableId) ||
    currentCableType.endsWith("underground");

  if (hasOverhead) {
    // If there's at least one overhead cable involved, create the poles
    createPoles(
      clientSettings,
      [getAssetById(allAssets, assetId)],
      undefined,
      cables,
      dispatchRedux,
      reference,
      hasOverhead,
      hasUnderground,
    );
  } else {
    // If there are no overhead cables, remove the poles
    removePoles([assetId], allAssets, dispatchRedux, hasOverhead, hasUnderground);
  }
};

export const getPoleTermLengthForTransformer = (clientSettings, assetId, cables, reference) => {
  // Check if the node is connected to an underground cable
  const hasUnderground = nodeIsConnectedToUndergroundLine(cables, {
    id: assetId,
  });

  return getDefaultPoleTermLength(clientSettings, reference, hasUnderground);
};
