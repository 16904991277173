import React, { useState, useContext, useEffect } from "react";
import { Input, InputGroup, ListGroupItem } from "reactstrap";
import { ToolContext } from "../context/ToolContext";
import { FormContext } from "../context/FormContext";
import { loadCrown, exportCrown, getBillOfMaterials } from "../services/apiService";
import WarningModal from "./WarningModal";
import ConfirmModal from "./ConfirmModal";
import { resetNetworkToolState, resetNetworkElements } from "../utils/networkFunctions";
import { gridRefToSetView } from "../utils/convertToSetView";
import domtoimage from "dom-to-image";
import { useMsal } from "@azure/msal-react";
import * as tab from "../constants/tabs";
import { formatGroupedConnectionsForBoM } from "../utils/networkFunctions";
import { cableHasDistributedCustomer } from "../utils/errorFunctions";
import { useDispatch, useSelector } from "react-redux";
import { buildNetwork, checkReduxNetworkPopulated, clearReduxNetwork } from "../app/networkSlice";
import useBearerToken from "../utils/useBearerToken";
import { addGenerator } from "../utils/addGeneratorForCosting";

const Crown = () => {
  const [schemeId, setSchemeId] = useState("");
  const [schemeVersion, setSchemeVersion] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showBoMWarningsModal, setShowBoMWarningsModal] = useState(false);
  const [showDataChangedWarningModal, setShowDataChangedWarningModal] = useState(false);
  const [showNoBoMWarningModal, setShowNoBoMWarningModal] = useState(false);
  const [canExportToCrown, setCanExportToCrown] = useState(false);
  const [bom, setBoM] = useState();
  const { toolState, setToolState } = useContext(ToolContext);
  const { dispatch, formState } = useContext(FormContext);
  const { showResults, assessmentRunning, leafletMap } = toolState;
  const { instance, accounts } = useMsal();
  const getToken = useBearerToken();

  const builtNetwork = useSelector((state) => buildNetwork(state));
  const isReduxNetworkPopulated = useSelector((state) => checkReduxNetworkPopulated(state));
  const transformers = useSelector((state) => state.network.present.transformers);
  const groupedConnections = useSelector((state) => state.network.present.groupedConnections);
  const connectionPoints = useSelector((state) => state.network.present.connectionPoints);
  const cables = useSelector((state) => state.network.present.cables);

  const dispatchRedux = useDispatch();

  useEffect(() => {
    if (!assessmentRunning && isReduxNetworkPopulated) {
      if (showResults && formState.crown.schemeId) {
        setCanExportToCrown(true);
      } else {
        setCanExportToCrown(false);
      }
    } else {
      setCanExportToCrown(false);
    }
  }, [
    assessmentRunning,
    isReduxNetworkPopulated,
    showResults,
    schemeId,
    schemeVersion,
    formState.crown.schemeId,
    setBoM,
  ]);

  const setToolStateProp = (prop, value) => {
    const _toolState = toolState;
    _toolState[prop] = {
      type: "warning",
      className: "warning",
      messages: value,
    };
    setToolState(_toolState);
  };

  const isValidSchemeInput = () => {
    if (isNaN(schemeId) || isNaN(schemeVersion)) {
      setToolStateProp("alert", [
        {
          description: "Scheme ID and Scheme Version must both be numeric",
        },
      ]);
      return false;
    }
    return true;
  };

  const getCrownData = async () => {
    setToolState({
      isLoading: "Loading CROWN data",
    });

    const token = await getToken(instance, accounts);
    const crown = await loadCrown(token, schemeId, schemeVersion);

    setToolState({
      isLoading: false,
    });

    if (!crown || crown.schemes[0] === null) {
      setToolStateProp("alert", [
        {
          description: "No data found for specified Scheme ID and Scheme Version",
        },
      ]);
      setCrownState({});
      return null;
    }

    return crown;
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const applyNetworkToCrown = () => {
    var crownParams = {
      crown: null,
      canClearNetwork: false,
      setToGridReference: false,
    };

    importCrownIfValidSchemeEntered(crownParams);
  };

  const newCrownNetwork = () => {
    var crownParams = {
      crown: null,
      canClearNetwork: true,
      setToGridReference: true,
    };

    importCrownIfValidSchemeEntered(crownParams);
  };

  const importCrownIfValidSchemeEntered = async (params) => {
    setShowConfirmModal(false);

    if (!isValidSchemeInput()) return;

    const crown = await getCrownData();
    if (!crown) return;

    params.crown = crown;

    importCrown(params);
  };

  const shouldImportCrown = async () => {
    if (!isValidSchemeInput()) return;

    const crown = await getCrownData();
    if (!crown) return;

    var crownParams = {
      crown: crown,
      canClearNetwork: true,
      setToGridReference: true,
    };

    isReduxNetworkPopulated ? setShowConfirmModal(true) : importCrown(crownParams);
  };

  const importCrown = (params) => {
    if (isReduxNetworkPopulated && params.canClearNetwork) {
      clearNetwork();
    }

    setCrownState(params.crown.schemes[0]);

    const _toolState = toolState;
    _toolState.activeTab = tab.FILE;
    setToolState(_toolState);

    if (leafletMap && params.crown.schemes[0].gridReference && params.setToGridReference) {
      gridRefToSetView(leafletMap, params.crown.schemes[0].gridReference);
    }
  };

  const setCrownState = (value) => {
    dispatch({
      form: "crown",
      obj: value,
      type: "REPLACE_STATE",
    });
  };

  const clearNetwork = () => {
    const _toolState = toolState;

    dispatchRedux(clearReduxNetwork());
    resetNetworkToolState(_toolState);
    resetNetworkElements();

    setCrownState({});

    setToolState(_toolState);
  };

  const getBoMData = async (canProcess) => {
    setShowDataChangedWarningModal(false);

    if (canProcess !== true) {
      return;
    }

    setSchemeId(formState.crown.schemeId);
    setSchemeVersion(formState.crown.schemeVersion);

    const clonedGroupConnections = addGenerator(groupedConnections);

    const bomData = {
      transformers: transformers,
      connectionPoints: connectionPoints,
      cables: cables,
      groupedConnections: formatGroupedConnectionsForBoM(clonedGroupConnections),
    };

    setToolState({ isLoading: "Calculating Bill of Materials" });

    const token = await getToken(instance, accounts);
    const response = await getBillOfMaterials(token, bomData);

    setToolState({ isLoading: false });

    if (!response || response?.errors) {
      if (response?.errors) {
        if (cableHasDistributedCustomer(response.errors)) {
          setBoM(response.billOfMaterials);
          setShowNoBoMWarningModal(true);
          return;
        }
      }
      setToolStateProp("alert", [
        {
          description: "Sorry, there was an error retrieving the Bill of Materials",
        },
      ]);
      return;
    }

    if (response?.messages?.length > 0) {
      const showWarnings = response.messages.every((msg) => msg.level === "Warning");
      errorHandling(response, showWarnings);
      if (showWarnings) {
        setBoM(response.billOfMaterials);
        setShowBoMWarningsModal(true);
      }
      return;
    }

    saveScreenshot(response.billOfMaterials);
  };

  const exportToCrown = async (canProcess) => {
    setShowBoMWarningsModal(false);
    setShowNoBoMWarningModal(false);

    if (canProcess !== true) {
      return;
    }

    saveScreenshot(bom);
  };

  const exportCrownData = async (bomData, screenshot) => {
    const exportData = {
      screenshot: screenshot,
      network: JSON.stringify({ ...builtNetwork, ...formState.network }),
      billOfMaterials: JSON.stringify(bomData.items),
    };

    const token = await getToken(instance, accounts);
    const result = await exportCrown(
      token,
      formState.crown.schemeId,
      formState.crown.schemeVersion,
      exportData,
    );

    setToolState({ isLoading: false });

    setToolStateProp("alert", [
      {
        description:
          !result || result.messages.length > 0
            ? "Sorry, there was an error exporting to CROWN"
            : "Data successfully exported to CROWN",
      },
    ]);
  };

  const errorHandling = (error, showWarning) => {
    const _toolState = toolState;
    const data = buildErrorData(
      error.messages || [{ description: "An unexpected error occurred" }],
      showWarning,
    );
    _toolState.alert = showWarning ? null : data;
    _toolState.costingFailed = !showWarning;
    _toolState.errors = data;
    setToolState(_toolState);
  };

  const buildErrorData = (data, showWarning) => {
    return {
      type: "error",
      className: showWarning ? "warning" : "danger",
      messages: data,
      isWarning: showWarning,
    };
  };

  const crownSchemeInputDataHasChanged = () => {
    return !(
      formState.crown.schemeId.toString() === schemeId.toString() &&
      formState.crown.schemeVersion.toString() === schemeVersion.toString()
    );
  };

  const saveScreenshot = (bomData) => {
    setToolState({ isLoading: "Exporting data to CROWN" });
    const printArea = document.getElementById("map");

    domtoimage
      .toPng(printArea, { height: 940, width: 1920 })
      .then((dataURL) => {
        exportCrownData(bomData, dataURL);
      })
      .catch((error) => {
        console.error("oops, something went wrong!", error);
      });
  };

  return (
    <>
      {showNoBoMWarningModal && (
        <WarningModal
          item={true}
          action={importCrown}
          msg={"Sorry, there is no BoM being estimated with distributed customers in the network."}
          dismissLabel="OK"
          hideYesButton={true}
          dismissAction={true}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          item={true}
          firstButtonAction={applyNetworkToCrown}
          secondButtonAction={newCrownNetwork}
          dismissButtonAction={closeConfirmModal}
          msg={
            "Click Apply to apply the scheme to the existing study, or New to clear the network and begin a new study​"
          }
        />
      )}
      {showDataChangedWarningModal && (
        <WarningModal
          item={true}
          action={getBoMData}
          msg={`Scheme Import data has changed. Clicking OK will Export to CROWN for scheme '${formState.crown.schemeId}' and Version '${formState.crown.schemeVersion}'.`}
          yesLabel="OK"
          dismissLabel="Cancel"
        />
      )}
      {showBoMWarningsModal && (
        <WarningModal
          item={true}
          action={exportToCrown}
          msg={"Continue exporting to CROWN with the following warnings?"}
          messages={toolState.errors.messages}
          yesLabel="Yes"
          dismissLabel="No"
        />
      )}
      <ListGroupItem className="bg-dark text-uppercase">Scheme Import</ListGroupItem>
      <div>
        <InputGroup className="pt-3 pb-3 text-white">
          <div className="container">
            <Input
              type="text"
              autoComplete="off"
              id="schemeId"
              placeholder="Scheme ID"
              bsSize="sm"
              value={schemeId}
              onChange={(e) => setSchemeId(e.target.value)}
            />
            <Input
              className="mt-2"
              type="text"
              autoComplete="off"
              id="schemeVersion"
              placeholder="Scheme Version"
              bsSize="sm"
              value={schemeVersion}
              onChange={(e) => setSchemeVersion(e.target.value)}
            />
            <div className="d-flex justify-content-end mt-3">
              <button
                type="button"
                id="importCrown"
                width={62}
                className="align-items-center btn btn-primary btn-sm"
                disabled={schemeId.length === 0 || schemeVersion.length === 0}
                onClick={() => shouldImportCrown()}
              >
                Search
              </button>
            </div>
          </div>
        </InputGroup>
      </div>
      <ListGroupItem className="bg-dark text-uppercase">Scheme Export</ListGroupItem>
      <div className="d-flex justify-content-start mt-3 ml-3">
        <button
          type="button"
          id="exportCrown"
          width={62}
          className="align-items-center btn btn-primary btn-sm"
          disabled={!canExportToCrown}
          onClick={() =>
            crownSchemeInputDataHasChanged()
              ? setShowDataChangedWarningModal(true)
              : getBoMData(true)
          }
        >
          Export to CROWN
        </button>
      </div>
    </>
  );
};

export default Crown;
