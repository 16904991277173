import { updateGroupedConnection } from "../../app/networkSlice";

export const applyPoleChange = (ringfencedFiltered, poleStatus, dispatchRedux) => {
  ringfencedFiltered.forEach((p) => {
    if (p.pole && p.pole.status !== poleStatus) {
      dispatchRedux(
        updateGroupedConnection({
          id: p.id,
          name: "pole",
          value: { ...p.pole, status: poleStatus },
        }),
      );
    }
  });
};

export const applyPotEndChange = (ringfencedFiltered, potEndStatus, dispatchRedux) => {
  ringfencedFiltered.forEach((p) => {
    if (p.potEnds && p.potEnds.some((q) => q.status !== potEndStatus)) {
      dispatchRedux(
        updateGroupedConnection({
          id: p.id,
          name: "potEnds",
          value: p.potEnds.map((q) => ({ ...q, status: potEndStatus })),
        }),
      );
    }
  });
};

export const applyLinkBoxChange = (ringfencedFiltered, linkBoxStatus, dispatchRedux) => {
  ringfencedFiltered.forEach((p) => {
    if (p.linkBox && p.linkBox.status !== linkBoxStatus) {
      dispatchRedux(
        updateGroupedConnection({
          id: p.id,
          name: "linkBox",
          value: { ...p.linkBox, status: linkBoxStatus },
        }),
      );
    }
  });
};
