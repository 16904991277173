import * as linkBoxConstants from "../constants/linkBox";
import { listConnectedCables } from "../utils/linkBoxFunctions";
import { getConstraint } from "../services/constraintsService";

export const parseResults = (
  asset,
  reference,
  networkCables,
  clickedAsset,
  groupedConnectionProperties,
  hasClearedResults,
) => {
  const { linkBox } = groupedConnectionProperties;

  const results = [];

  if (
    clickedAsset &&
    asset.startAssetId &&
    asset.endAssetId &&
    linkBox?.numberOfWays === linkBoxConstants.FOURWAY &&
    (asset.annotation?.length ?? 0) === 0
  ) {
    const cables = listConnectedCables(networkCables, clickedAsset);

    const title = cables.find((p) => p.cableId === asset.id)?.title;

    if (title?.length > 0) {
      results.push({
        category: "annotation",
        name: "PseudoAnnotation",
        value: title,
        unit: "",
        class: "white",
        constraint: false,
        hide: false,
      });
    }
  }

  const fuseSizeFactor = (asset, value, res) => {
    let val = asset[value];
    if (res.name === "Fuse Size (A)") {
      val = asset["feederFuseSize"];
    }

    return val;
  };

  reference.filterResults.forEach((category) => {
    category.info.forEach((res) => {
      const value = res.season ? res.season + res.value : res.value;
      if (asset[value] && res.isSet) {
        results.push({
          name: res.name,
          value: fuseSizeFactor(asset, value, res),
          category: res.value,
          unit: res.unit,
          class: res.color,
          constraint: getConstraint(asset, value),
          hide:
            (hasClearedResults && res.name === "Fuse Size (A)") ||
            (asset.isFirstLeg === "false" &&
              (res.name === "Identifiers" || (!asset.hasFuseSize && res.name === "Fuse Size (A)"))),
        });
      }
      if (res.value === "feederFuseSize" && res.isSet) {
        if (asset.hasFuseWarning) {
          results.push({
            category: "feederFuseSize",
            name: "Fuse Warning",
            value: "Fault level",
            unit: "",
            class: "sea-green",
            constraint: true,
            hide: false,
          });
        }
      }
    });
  });

  if (asset.hasFuseMaxCurrentWarning) {
    let currentResult = results.filter((r) => r.name === "Fuse Size (A)")[0];
    if (currentResult) {
      currentResult.class = "sea-green";
      currentResult.constraint = true;
    }
  }

  return results;
};
