import { useContext } from "react";
import { useDispatch } from "react-redux";
import { Button, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import { clearResults, updateGroupedConnection } from "../app/networkSlice";
import { getResultProperties } from "../utils/referenceFunctions";
import DataAlert from "./DataAlert";

const NodeFuseSelector = ({ handleDefault }) => {
  const { toolState, setToolState } = useContext(ToolContext);
  const { formState, dispatch } = useContext(FormContext);
  const { groupedConnectionProperties, reference } = formState;
  const { fuse, fuseIsDefault } = groupedConnectionProperties;
  const { clickedAsset } = toolState;

  const dispatchRedux = useDispatch();

  const selectList = [
    { name: "No Rating", value: 0 },
    ...formState.reference.fuses.ratings.map((p) => ({
      name: p.toString(),
      value: p,
    })),
  ];

  const fuseSelected = () => fuse && fuse >= 0;

  const updateFuse = (e) => {
    updateNode(e.target.value);
  };

  const addOrRemoveFuse = (e) => {
    e.preventDefault();
    if (fuse) {
      updateNode(undefined);
      return;
    }

    const formData = new FormData(e.target);
    var fuseOption = formData.get("fuseOption");
    updateNode(fuseOption);
  };

  const hideResults = () => {
    const _toolState = toolState;
    _toolState.showResults = false;
    setToolState(_toolState);
    dispatchRedux(clearResults(getResultProperties(reference)));
  };

  const updateNode = (value) => {
    hideResults();

    dispatch({
      form: "groupedConnectionProperties",
      field: "fuse",
      value: value,
      type: "UPDATE_FIELD",
    });
    if (clickedAsset) {
      dispatchRedux(updateGroupedConnection({ id: clickedAsset.id, name: "fuse", value }));
    }
  };

  return (
    <div
      className="bg-white-alpha"
      style={{
        padding: ".75rem",
        margin: ".75rem 0",
        marginBottom: "1rem",
      }}
    >
      <h6
        className="text-light"
        style={{
          fontSize: ".75rem",
          textTransform: "uppercase",
          fontWeight: 200,
        }}
      >
        Add Fuse (A)
        {fuseIsDefault && <DataAlert updateDefault={() => handleDefault("fuse")} />}
      </h6>
      <form onSubmit={addOrRemoveFuse}>
        <div className="text-light d-flex">
          <Input
            type="select"
            name="fuseOption"
            className="w-100"
            value={fuse ?? 0}
            onChange={updateFuse}
          >
            {selectList.map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </Input>

          <Button size="sm" color={fuseSelected() ? "danger" : "success"} className="ml-2">
            <FontAwesomeIcon icon={fuseSelected() ? faMinus : faPlus} />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NodeFuseSelector;
