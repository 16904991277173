export const isPositiveNonDecimalNumber = (feederNumber) => {
  // Ignore non-numbers
  if (isNaN(feederNumber)) return false;
  // Ignore decimal numbers
  if (feederNumber - Math.floor(feederNumber) !== 0) return false;
  // Ignore negative numbers and zero
  if (Math.sign(feederNumber) < 1) return false;
  return true;
};
export const formatLengthPrecision = (value) => {
  var parsedValue = parseFloat(value);
  if (parsedValue > 0 && parsedValue < 0.01) {
    return parsedValue.toFixed(10).replace(/\.?0+$/, "");
  }
  return parsedValue.toFixed(2);
};
export const formatLength = (value) => {
  if (value === "") {
    value = "0.00";
  } else {
    value = formatLengthPrecision(value);
  }
  return value;
};
