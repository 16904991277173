import React, { useState } from "react";
import { ButtonGroup, Button, Modal, ModalBody } from "reactstrap";

const ConfirmModal = ({
  item,
  msg,
  firstButtonAction,
  secondButtonAction,
  dismissButtonAction,
  firstButtonLabel = "Apply",
  secondButtonLabel = "New",
  dismissButtonLabel = "Cancel",
}) => {
  const [modal, setModal] = useState(true);

  const toggle = () => {
    setModal(!modal);
  };

  const handleFirstButtonAction = (value = false) => {
    firstButtonAction(value);
  };

  const handleSecondButtonAction = (value = false) => {
    secondButtonAction(value);
  };

  const handleDismissButtonAction = () => {
    dismissButtonAction(false);
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
        <div className="bg-warning text-light">
          <ModalBody className="d-flex justify-content-between">
            <span>{msg}</span>
            <ButtonGroup style={{ marginLeft: 10, height: 35 }}>
              <Button
                outline
                color="light"
                size="sm"
                onClick={() => {
                  toggle();
                  handleFirstButtonAction(item);
                }}
              >
                {firstButtonLabel}
              </Button>
              <Button
                outline
                color="light"
                size="sm"
                onClick={() => {
                  toggle();
                  handleSecondButtonAction(item);
                }}
                className="text-nowrap"
              >
                {secondButtonLabel}
              </Button>
              <Button
                outline
                color="light"
                size="sm"
                onClick={() => {
                  toggle();
                  handleDismissButtonAction();
                }}
              >
                {dismissButtonLabel}
              </Button>
            </ButtonGroup>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
